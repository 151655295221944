<template>
    <div :class="{ 'dark text-white-dark': store.semidark }">
        <nav :class="{ 'sidebar-icon-only': store.sidebarIconOnly }" class="sidebar fixed bottom-0 top-0 z-50 h-full min-h-screen w-[260px] shadow-[5px_0_25px_0_rgba(94,92,154,0.1)] transition-all duration-300">
            <div class="h-full bg-white dark:bg-[#0e1726]">
                <div class="flex items-center justify-between px-4 py-3">
                    <NuxtLink to="/" class="main-logo flex shrink-0 items-center">
                        <img class="ml-[25px] w-32 flex-none" src="/assets/images/teamss_logo.png" alt="" />
                    </NuxtLink>
                    <a
                        href="javascript:;"
                        class="collapse-icon flex h-8 w-8 items-center rounded-full transition duration-300 hover:bg-gray-500/10 hover:text-primary rtl:rotate-180 dark:text-white-light dark:hover:bg-dark-light/10"
                        @click="store.toggleSidebar()"
                    >
                        <icon-carets-down class="m-auto rotate-90" />
                    </a>
                </div>
                <client-only>
                    <perfect-scrollbar
                        :options="{
                            swipeEasing: true,
                            wheelPropagation: false,
                        }"
                        class="relative h-[calc(100vh-80px)]"
                    >
                        <ul class="relative space-y-0.5 p-4 py-0 font-semibold">
                            <li class="nav-item">
                                <ul>
                                    <li class="nav-item" v-if="hasAccess('Dashboard')">
                                        <NuxtLink to="/admin" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-dashboard class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Dashboard</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item" v-if="hasAccess('Contact List')">
                                        <NuxtLink to="/admin/apps/contacts" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-contacts class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Contact List</span>
                                            </div>
                                        </NuxtLink>
                                    </li>

                                    <li class="menu nav-item" v-if="hasAccess('CRM')">
                                        <button
                                            type="button"
                                            class="nav-link group w-full"
                                            :class="{ active: activeDropdown === 'CRM' }"
                                            @click="activeDropdown === 'CRM' ? (activeDropdown = null) : (activeDropdown = 'CRM')"
                                        >
                                            <div class="flex items-center">
                                                <icon-menu-invoice class="shrink-0 group-hover:!text-primary" />

                                                <span class="text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Opportunities</span>
                                            </div>
                                            <div :class="{ '-rotate-90 rtl:rotate-90': activeDropdown !== 'CRM' }">
                                                <icon-caret-down />
                                            </div>
                                        </button>
                                        <vue-collapsible :isOpen="activeDropdown === 'CRM'">
                                            <ul class="sub-menu text-gray-500">
                                                <li>
                                                    <NuxtLink :exact-active-class="'active'" exact to="/admin/apps/crm?type=BUY" @click="toggleMobileMenu">{{ $t('Buy') }}</NuxtLink>
                                                </li>
                                                <li>
                                                    <NuxtLink :exact-active-class="'active'" exact to="/admin/apps/crm?type=SELL" @click="toggleMobileMenu">{{ $t('Sell') }}</NuxtLink>
                                                </li>
                                                <li>
                                                    <NuxtLink :exact-active-class="'active'" exact to="/admin/apps/crm?type=RENT" @click="toggleMobileMenu">{{ $t('Rent') }}</NuxtLink>
                                                </li>
                                                <li>
                                                    <NuxtLink :exact-active-class="'active'" exact to="/admin/apps/crm?type=GENERAL" @click="toggleMobileMenu">{{ $t('General') }}</NuxtLink>
                                                </li>
                                            </ul>
                                        </vue-collapsible>
                                    </li>
                                    <!-- <li class="nav-item" v-if="hasAccess('CRM')">
                                        <NuxtLink to="/admin/apps/crm" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-users class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Opportunities</span>
                                            </div>
                                        </NuxtLink>
                                    </li> -->
                                    <li class="nav-item" v-if="hasAccess('Listing')">
                                        <NuxtLink to="/admin/apps/listing" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-todo class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Listing</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item" v-if="hasAccess('Marketing')">
                                        <NuxtLink to="/admin/apps/marketing" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-invoice class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Marketing</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item" v-if="hasAccess('Smart Action')">
                                        <NuxtLink to="/admin/apps/smartaction" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-elements class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Smart Action</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <!-- <li class="nav-item" v-if="hasAccess('Opportunities')">
                                        <NuxtLink to="/admin/apps/opportunities" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-font-icons class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark"></span>
                                            </div>
                                        </NuxtLink>
                                    </li> -->
                                    <li class="nav-item" v-if="hasAccess('Task To Do')">
                                        <NuxtLink to="/admin/apps/todolist" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-notes class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Task To Do</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item" v-if="hasAccess('Business')">
                                        <NuxtLink to="/admin/apps/bussiness" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-scrumboard class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Business</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item" v-if="hasAccess('Financial')">
                                        <NuxtLink to="/admin/apps/financials" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-invoice class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Financials</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                    <li class="nav-item">
                                        <NuxtLink to="/admin/apps/invoice/preview" class="group" @click="toggleMobileMenu">
                                            <div class="flex items-center">
                                                <icon-menu-authentication class="shrink-0 group-hover:!text-primary" />
                                                <span class="menu-text text-black ltr:pl-3 rtl:pr-3 dark:text-[#506690] dark:group-hover:text-white-dark">Settings</span>
                                            </div>
                                        </NuxtLink>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </perfect-scrollbar>
                </client-only>
            </div>
        </nav>
    </div>
</template>

<script lang="ts" setup>
    import { ref, onMounted } from 'vue';
    import { useAppStore } from '@/stores/index';
    import VueCollapsible from 'vue-height-collapsible/vue3';
    import { useAuthStore } from '@/stores/authStore';
    const store = useAppStore();
    const authStore = useAuthStore();
    authStore.loadUser()
    const activeDropdown: any = ref('');
    const subActive: any = ref('');
    definePageMeta({
        middleware: 'auth',
    });

    onMounted(() => {
        if(!authStore.partnerId){
            navigateTo('/')
        }
        console.log(authStore.partnerId, 'authStore22')
        setTimeout(() => {
            const selector = document.querySelector('.sidebar ul a[href="' + window.location.pathname + '"]');
            if (selector) {
                selector.classList.add('active');
                const ul: any = selector.closest('ul.sub-menu');
                if (ul) {
                    let ele: any = ul.closest('li.menu').querySelectorAll('.nav-link') || [];
                    if (ele.length) {
                        ele = ele[0];
                        setTimeout(() => {
                            ele.click();
                        });
                    }
                }
            }
        });
    });

    const toggleMobileMenu = () => {
        if (window.innerWidth < 1024) {
            store.toggleSidebar();
        }
    };

    const hasAccess = (menuName: string) => {
        return authStore.getUser.accessIds.some((access) => access.menu === menuName);
    };
</script>

<style scoped>
.sidebar-icon-only {
    width: 60px;
}
.sidebar-icon-only .menu-text {
    display: none;
}
</style>
